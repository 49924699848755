import styled from '@emotion/styled';
import { PopupButton } from 'react-calendly';

import { colors, fonts } from '../../emotion/constants';
import { ButtonProps } from '../atoms/Button';

interface CalendlyButtonProps extends ButtonProps {
  url?: string;
  children: string;
  'data-aos'?: string;
}

const StyledButton = styled(PopupButton)<ButtonProps>`
  font-family: ${fonts.mono};
  display: inline-flex;
  font-size: 1.125rem;
  padding: 0.75rem 1.5625rem;
  justify-content: center;
  align-items: center;
  gap: 1.0625rem;
  text-transform: uppercase;
  border: none;
  cursor: pointer;

  ${({ marginTop }) => marginTop && 'margin-top: 2rem;'}
  ${({ marginBottom }) => marginBottom && 'margin-bottom: 2rem;'}
  ${({ centered }) =>
    centered &&
    `
    margin-left: auto;
    margin-right: auto;
  `}

  ${({ variant }) =>
    variant === 'black' &&
    `
    background-color: ${colors.black};
    color: ${colors.white};
  `}

  ${({ variant }) =>
    variant === 'yellow' &&
    `
    background-color: ${colors.yellow};
    color: ${colors.black};
  `}

  ${({ variant }) =>
    variant === 'transparent' &&
    `
    background-color: transparent;
    color: inherit;
    border: solid 1px;
  `}
`;

export default function CalendlyButton({
  url,
  children,
  variant = 'yellow',
  'data-aos': dataAOS,
  ...props
}: CalendlyButtonProps) {
  return (
    <div data-aos={dataAOS}>
      <StyledButton
        url={url}
        text={children}
        variant={variant}
        {...props}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById('root')}
      />
    </div>
  );
}
