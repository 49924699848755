import styled from '@emotion/styled';
import { ElementType } from 'react';

import { fonts } from '../../emotion/constants';
import { breakpoints } from '../../emotion/constants';
import { SharedAtomProps } from './typings';

interface MonospaceProps extends SharedAtomProps {
  small?: boolean;
  large?: boolean;
  as?: ElementType<any>;
}

const StyledMonospace = styled.span<MonospaceProps>`
  font-family: ${fonts.mono};
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;

  ${({ large }) =>
    large
      ? `
      font-size: 1rem;
      line-height: 1.0625rem; /* 106.25% */

      ${breakpoints.desktopUp} {
        font-size: 1.125rem;
        line-height: 94.4%;
      }
  `
      : `
  font-size: 0.875rem;
  line-height: 130%;
  `}
`;
export default function Monospace(props: MonospaceProps) {
  const { children, large = false, ...otherProps } = props;
  return (
    <StyledMonospace large={large} {...otherProps}>
      {children}
    </StyledMonospace>
  );
}
