import styled from '@emotion/styled';

import { fonts } from '../../emotion/constants';
import { HeadingProps } from './typings';

const StyledH3 = styled.h3<HeadingProps>`
  font-family: ${fonts.title};
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: 0.03rem;

  ${({ noMargin }) => noMargin && 'margin: 0;'}
`;

export default function H3(props: HeadingProps) {
  const { children, noMargin = false, as = 'h3', ...otherProps } = props;
  return (
    <StyledH3 as={as} noMargin={noMargin} {...otherProps}>
      {children}
    </StyledH3>
  );
}
