import { Global } from '@emotion/react';
import styled from '@emotion/styled';

import { Body, Section, Subheading } from '../components/atoms';
import globalStyles from '../emotion/globalStyles';

const FullHeightSection = styled(Section)`
  height: 100vh;
  justify-content: center;
`;

export default function ErrorView() {
  return (
    <>
      <Global styles={globalStyles} />
      <FullHeightSection>
        <Subheading>No pudimos encontrar tu cotización</Subheading>
        <Body>
          El enlace al que intentaste entrar puede estar mal escrito o está
          desactivado
        </Body>
      </FullHeightSection>
    </>
  );
}
