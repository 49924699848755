import { createClient } from '@sanity/client';

import { SANITY_EDIT_TOKEN, SANITY_PROJECT_ID } from './constants';

const sanityClient = createClient({
  projectId: SANITY_PROJECT_ID,
  dataset: 'production',
  useCdn: true,
  apiVersion: '2024-01-22', // use current date (YYYY-MM-DD) to target the latest API version
  token: SANITY_EDIT_TOKEN, // Only if you want to update content with the client
});

export default sanityClient;
