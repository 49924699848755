/** @jsxImportSource @emotion/react */
import { CheckCircleFill } from '@emotion-icons/bootstrap';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useWindowSize } from '@uidotdev/usehooks';

import { useApp } from '../../contexts/AppContext';
import { breakpoints, colors, screenWidths } from '../../emotion/constants';
import Logo from '../../images/logo-black.svg';
import LogoSmall from '../../images/logo-small.svg';
import Button from '../atoms/Button';
import Section from '../atoms/Section';

const NavbarButton = styled(Button)`
  font-size: 1rem;

  ${breakpoints.phoneDown} {
    font-size: 0.9rem;
  }
`;

interface NavBarProps {
  className?: string;
}

export default function NavBar({ className }: NavBarProps) {
  const { width } = useWindowSize();
  const { setModalVisible, mutationsLoading, proposalData } = useApp();

  return (
    <>
      <div
        className={className}
        css={css`
          position: sticky;
          top: 0;
          z-index: 5;
        `}
        id="navbar"
      >
        <Section
          as="nav"
          css={(theme) => css`
            color: ${colors.black};
            background: ${colors.white};
            justify-content: space-between;
            align-items: center;
            padding-top: 0.86rem;
            padding-bottom: 0.86rem;
            flex-direction: row;
            border-bottom: none;
            position: 'relative';
            z-index: 1;

            ${breakpoints.desktopUp} {
              padding-top: 1.12rem;
              padding-bottom: 1.12rem;
            }
          `}
        >
          <div>
            {width >= screenWidths.tabletPortraitUp ? (
              <img
                alt="Bengaru logo"
                src={Logo}
                css={{
                  width: 145,
                  height: 'auto',
                }}
                // placeholder="blur"
              />
            ) : (
              <img
                alt="Bengaru logo"
                src={LogoSmall}
                css={{
                  width: 80,
                  height: 'auto',
                }}
                // placeholder="blur"
              />
            )}
          </div>
          <div
            css={css`
              display: flex;
              gap: 0.94rem;

              ${breakpoints.desktopUp} {
                gap: 1.79rem;
              }
            `}
          >
            {proposalData?.approved ? (
              <NavbarButton variant="ghost" disabled css={{ opacity: 1 }}>
                Cotización aprobada
                <CheckCircleFill
                  css={{ color: 'green', height: '1.25rem', width: '1.25rem' }}
                />
              </NavbarButton>
            ) : (
              <NavbarButton
                loading={mutationsLoading}
                disabled={mutationsLoading}
                onClick={() => setModalVisible(true)}
              >
                Aprobar cotización
              </NavbarButton>
            )}
          </div>
        </Section>
      </div>
    </>
  );
}
