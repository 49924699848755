export const SENDGRID_PROPOSAL_CONFIRMATION_TEMPLATE_ID: string =
  process.env.REACT_APP_SENDGRID_PROPOSAL_CONFIRMATION_TEMPLATE_ID;
export const SENDGRID_PROPOSAL_ADMIN_CONFIRMATION_TEMPLATE_ID: string =
  process.env.REACT_APP_SENDGRID_PROPOSAL_ADMIN_CONFIRMATION_TEMPLATE_ID;
export const SENDGRID_SERVERLESS_FUNCTION: string =
  process.env.REACT_APP_SENDGRID_SERVERLESS_FUNCTION;
export const SANITY_PROJECT_ID: string =
  process.env.REACT_APP_SANITY_PROJECT_ID;
export const SANITY_EDIT_TOKEN: string =
  process.env.REACT_APP_SANITY_EDIT_TOKEN;
export const BASEURL: string = process.env.REACT_APP_BASEURL;
