import styled from '@emotion/styled';

import { breakpoints, fonts } from '../../emotion/constants';
import { SharedAtomProps } from './typings';

interface AllCapsTitleProps extends SharedAtomProps {
  small?: boolean;
  large?: boolean;
}

const StyledAllCapsTitle = styled.h2<AllCapsTitleProps>`
  font-family: ${fonts.title};
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;

  ${({ large }) =>
    large
      ? `
    font-size: 2.3125rem;
    line-height: 110%;
    letter-spacing: 0.04625rem;

    ${breakpoints.desktopUp} {
      font-size: 6.875rem;
      line-height: 110%;
      letter-spacing: 0.1375rem;
    }
  `
      : `
  font-size: 2.1875rem;
    line-height: 105%;
    letter-spacing: 0.04375rem;

    ${breakpoints.desktopUp} {
      font-size: 4.375rem;
      line-height: 105%;
      letter-spacing: 0.0875rem;
    }
  `}
`;
export default function AllCapsTitle(props: AllCapsTitleProps) {
  const { children, large = false, ...otherProps } = props;
  return (
    <StyledAllCapsTitle large={large} {...otherProps}>
      {children}
    </StyledAllCapsTitle>
  );
}
