/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';

import { colors } from '../../../emotion/constants';
import { Caption } from '../../atoms';
import { FooterSection } from './subcomponents';

const StyledLink = styled.a<{ themed?: boolean }>`
  color: ${({ theme, themed }) => (themed ? theme.colors.text : colors.white)};
  text-decoration: none;
  text-transform: uppercase;
`;

export default function Footer() {
  return (
    <FooterSection as="footer">
      <StyledLink href="#">Política de Privacidad</StyledLink>
      <Caption css={{ color: colors.gray_1 }}>
        2024 BNGR PROFESSIONAL SERVICES. All Rights Reserved
      </Caption>
    </FooterSection>
  );
}
