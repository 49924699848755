import styled from '@emotion/styled';
import { Oval } from 'react-loading-icons';

import { colors, fonts } from '../../emotion/constants';
import { SharedAtomProps } from './typings';

const StyledButton = styled.button<ButtonProps>`
  font-family: ${fonts.mono};
  display: inline-flex;
  font-size: 1.125rem;
  padding: 0.75rem 1.5625rem;
  justify-content: center;
  align-items: center;
  gap: 1.0625rem;
  text-transform: uppercase;
  border: none;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    opacity: 0.8;
  `}

  ${({ marginTop }) => marginTop && 'margin-top: 2rem;'}
  ${({ marginBottom }) => marginBottom && 'margin-bottom: 2rem;'}
  ${({ centered }) =>
    centered &&
    `
    margin-left: auto;
    margin-right: auto;
  `}

  ${({ variant }) =>
    variant === 'black' &&
    `
    background-color: ${colors.black};
    color: ${colors.white};
  `}

  ${({ variant }) =>
    variant === 'yellow' &&
    `
    background-color: ${colors.yellow};
    color: ${colors.black};
  `}

  ${({ variant }) =>
    variant === 'transparent' &&
    `
    background-color: transparent;
    color: inherit;
    border: solid 1px;
  `}

  ${({ variant }) =>
    variant === 'ghost' &&
    `
    background-color: transparent;
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  `}
`;

type Variant = 'yellow' | 'transparent' | 'black' | 'ghost';

export interface ButtonProps extends SharedAtomProps {
  as?: 'button' | 'a';
  disabled?: boolean;
  href?: string;
  variant?: Variant;
  onClick?: () => void;
  marginTop?: boolean;
  marginBottom?: boolean;
  centered?: boolean;
  loading?: boolean;
}

export default function Button({
  as = 'button',
  variant = 'yellow',
  children,
  loading,
  ...otherProps
}: ButtonProps) {
  return (
    <StyledButton className="button" variant={variant} as={as} {...otherProps}>
      {children}
      {loading && <Oval stroke="#1c1c1c" height={16} width={16} />}
    </StyledButton>
  );
}
