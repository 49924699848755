/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { addDays, format } from 'date-fns';
import { es } from 'date-fns/locale';
import Skeleton from 'react-loading-skeleton';

import { breakpoints, colors } from '../../emotion/constants';
import Heading from './Heading';
import Monospace from './Monospace';
import Section from './Section';

const StyledSection = styled(Section)<{
  color: string;
  backgroundImage?: string;
  textColor?: string;
}>`
  padding-top: 3.5rem;
  padding-bottom: 2.35rem;
  color: ${colors.black};

  ${({ backgroundImage, textColor, color }) =>
    backgroundImage &&
    `
    background: linear-gradient(180deg, rgba(28,28,28,0.4) 0%, rgba(28,28,28,0.4) 100%), url(${backgroundImage});
    background-color: ${color};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-bottom: solid 1px ${color};
    color: ${textColor};
    ${breakpoints.tabletLandscapeUp} {
      background-attachment: fixed;
    }
  `}

  ${breakpoints.desktopUp} {
    padding-top: 9.5rem;
  }
`;

interface ProposalProps {
  title: string;
  creationDate: Date;
  client?: string;
  tags?: string[];
  color?: string;
  className?: string;
  backgroundImage?: string;
  textColor?: string;
}

export default function Proposal({
  title,
  creationDate,
  tags,
  client,
  color,
  className,
  backgroundImage,
  textColor = colors.white,
}: ProposalProps) {
  const validDate = addDays(new Date(creationDate), 30);
  return (
    <StyledSection
      className={className}
      color={color}
      backgroundImage={backgroundImage}
      textColor={textColor}
    >
      <Heading
        css={css`
          margin-bottom: 7rem;
          ${breakpoints.tabletPortraitUp} {
            margin-bottom: 13.75rem;
          }
        `}
      >
        {title || <Skeleton count={2} width={500} />}
      </Heading>

      <div
        css={css`
          display: flex;
          gap: 1rem 4rem;

          ${breakpoints.phoneDown} {
            flex-direction: column;
          }
        `}
      >
        <Monospace large>
          {client ? `Cliente: ${client}` : <Skeleton width={180} />}
        </Monospace>
        <Monospace large>
          {creationDate ? (
            `Válido hasta: ${format(validDate, 'PPPP', { locale: es })}`
          ) : (
            <Skeleton width={300} />
          )}
        </Monospace>
        {tags && (
          <Monospace large css={{ marginLeft: '4.5rem' }}>
            {tags.join(', ')}
          </Monospace>
        )}
      </div>
    </StyledSection>
  );
}
