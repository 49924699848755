import styled from '@emotion/styled';

import { breakpoints, fonts } from '../../emotion/constants';
import { SharedAtomProps } from './typings';

interface BodyProps extends SharedAtomProps {
  noMargin?: boolean;
  centered?: boolean;
}

const StyledBody = styled.p<BodyProps>`
  font-family: ${fonts.text};
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: justify;

  ${({ noMargin }) => noMargin && 'margin: 0;'}
  ${({ centered }) => centered && 'text-align: center'}

  ${breakpoints.desktopUp} {
    font-size: 1.125rem;
  }
`;

export default function Body(props: BodyProps) {
  const { children, ...otherProps } = props;
  return <StyledBody {...otherProps}>{children}</StyledBody>;
}
