import styled from '@emotion/styled';
import Skeleton from 'react-loading-skeleton';

import { breakpoints, colors } from '../../emotion/constants';
import { HTMLLayoutElementType } from '../../emotion/typings';
import Body from './Body';
import Heading from './Heading';
import { SharedAtomProps } from './typings';

interface SectionProps extends SharedAtomProps {
  as?: HTMLLayoutElementType;
  dark?: boolean;
  light?: boolean;
  backgroundColor?: string;
  textColor?: string;
  loading?: boolean;
}

const StyledSection = styled.div<SectionProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 2rem 2rem;

  & p {
    width: 100%;
  }

  ${({ light }) =>
    light &&
    `
    background-color: ${colors.white};
    color: ${colors.black};
  `}

  ${({ dark }) =>
    dark &&
    `
    background-color: ${colors.black};
    color: ${colors.white};
  `}
  
  ${({ backgroundColor, textColor }) =>
    backgroundColor &&
    `
    background-color: ${backgroundColor};
    color: ${textColor};
  `}
  
  ${breakpoints.desktopUp} {
    padding: 6rem 14rem;
  }

  ${breakpoints.largeDesktopUp} {
    padding: 6rem 16rem;
  }
`;

export default function Section(props: SectionProps) {
  const {
    children,
    dark = false,
    light = true,
    backgroundColor,
    textColor = colors.black,
    as = 'div',
    loading,
    ...otherProps
  } = props;
  return (
    <StyledSection
      dark={dark}
      light={light}
      backgroundColor={backgroundColor}
      textColor={textColor}
      as={as}
      {...otherProps}
    >
      {loading ? (
        <>
          <Heading>
            <Skeleton width={400} />
          </Heading>
          <Body>
            <Skeleton count={6} />
          </Body>
        </>
      ) : (
        children
      )}
    </StyledSection>
  );
}
