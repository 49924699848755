import axios from 'axios';

import {
  SENDGRID_PROPOSAL_ADMIN_CONFIRMATION_TEMPLATE_ID,
  SENDGRID_PROPOSAL_CONFIRMATION_TEMPLATE_ID,
  SENDGRID_SERVERLESS_FUNCTION,
} from '../constants';

export interface SendGridObject {
  to: string;
  templateId: string;
  from: {
    email: string;
    name: string;
  };
  templateParams: { [key: string]: string };
}

export function buildSendgridObject(
  toEmail: string,
  templateId: string,
  templateParams: { [key: string]: string },
): SendGridObject | undefined {
  const baseObject = {
    to: toEmail,
    from: {
      email: 'hola@bengaru.co', // TODO: In future, we should be variable depending on client language
      name: 'Bengaru',
    },
  };
  return {
    ...baseObject,
    templateId,
    templateParams,
  };
}

export async function sendTransactionalMail(
  SendGridObject: SendGridObject,
): Promise<any> {
  try {
    const response = await axios.post(
      SENDGRID_SERVERLESS_FUNCTION,
      JSON.stringify(SendGridObject),
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function sendEmailToClient(
  toEmail: string,
  businessName: string,
  contactName: string,
) {
  const sendgridobject = buildSendgridObject(
    toEmail,
    SENDGRID_PROPOSAL_CONFIRMATION_TEMPLATE_ID,
    { contact_name: contactName, business_name: businessName },
  );

  return await sendTransactionalMail(sendgridobject);
}

export async function sendEmailToAccountManager(
  toEmail: string,
  seller_name: string,
  proposal_url: string,
  client_name: string,
  contact_name: string,
  client_email: string,
  client_phone?: string,
) {
  const sendgridobject = buildSendgridObject(
    toEmail,
    SENDGRID_PROPOSAL_ADMIN_CONFIRMATION_TEMPLATE_ID,
    {
      seller_name,
      client_name,
      proposal_url,
      contact_name,
      client_email,
      client_phone,
    },
  );

  return await sendTransactionalMail(sendgridobject);
}
