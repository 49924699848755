import AOS from 'aos';
import 'aos/dist/aos.css';
import { createContext, useContext, useEffect, useState } from 'react';

interface IAppContext {
  mutationsLoading: boolean;
  setMutationsLoading: (loading: boolean) => void;
  modalVisible: boolean;
  setModalVisible: (visible: boolean) => void;
  proposalData: any;
  setProposalData: (newProposalData: any) => void;
}

export const AppContext = createContext<IAppContext>({
  mutationsLoading: false,
  setMutationsLoading: () => {},
  modalVisible: false,
  setModalVisible: () => {},
  proposalData: {},
  setProposalData: () => {},
});
AppContext.displayName = 'App';

export function AppProvider({ children }) {
  const [mutationsLoading, setMutationsLoading] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [proposalData, setProposalData] = useState();

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <AppContext.Provider
      value={{
        mutationsLoading,
        setMutationsLoading,
        modalVisible,
        setModalVisible,
        proposalData,
        setProposalData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export const useApp = () => useContext(AppContext);
