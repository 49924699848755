import styled from '@emotion/styled';
import { ReactNode } from 'react';
import ReactModal from 'react-modal';

import { colors } from '../../emotion/constants';

const StyledModal = styled(ReactModal)`
  max-width: 600px;
  max-height: 500px;
  background: ${colors.white};
  padding: 2.5rem;
  border-radius: 8px;
  box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
`;

interface ModalProps {
  isOpen: boolean;
  children: ReactNode;
  onClose?: () => void;
}

export default function Modal({ children, isOpen, onClose }: ModalProps) {
  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'transparent',
        },
      }}
    >
      {children}
    </StyledModal>
  );
}
