import { useEffect, useState } from 'react';

import sanityClient from '../sanity.cli';

export default function useProposal(proposalId: string) {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [error, setError] = useState<any>();
  const [shouldRefetch, refetch] = useState({});

  useEffect(() => {
    const fetch = () => {
      if (proposalId) {
        setLoading(true);
        sanityClient
          .fetch(
            `*[_type == "proposal" && _id == "${proposalId}"]{
              ...,
              client->,
              accountManager->,
              sections[] {
                ...,
                content[] {
                  ...,
                  images[] {
                    ...,
                    asset -> {
                      ...,
                      "_key": _id
                    }
                  },
                  asset -> {
                  ...,
                  "_key": _id
                }
              }
            },
            mainImage {
              asset -> {
                ...,
                "_key": _id
              }
            }
          }`,
          )
          .then((data) => {
            if (data && data.length) {
              setData(data[0]);
            } else {
              setError('Not found');
            }
          })
          .catch((e) => {
            setError(e);
          })
          .finally(() => setLoading(false));
      }
    };

    fetch();
  }, [proposalId, shouldRefetch, refetch]);

  return { loading, data, error, refetch: () => refetch({}) };
}
