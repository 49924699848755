import sanityClient from '../sanity.cli';

export async function updateProposalStatus(
  proposalId: string,
  approved: boolean = true,
) {
  try {
    const response = await sanityClient
      .patch(proposalId)
      .set({ approved: approved })
      .commit();
    return response;
  } catch {
    console.log('error');
  }
}
