/** @jsxImportSource @emotion/react */
import { PortableTextReactComponents } from '@portabletext/react';

import { Body, Button, Heading, Image, Subheading } from '../components/atoms';
import Masonry from '../components/atoms/Masonry';
import CalendlyButton from '../components/molecules/CalendlyButton';
import { AppContext } from '../contexts/AppContext';

const isGIF = (asset: any) => {
  if (asset?.extension === 'gif') {
    return true;
  }
  return false;
};

const PortableTextComponents = {
  block: {
    h1: ({ children }) => <Heading data-aos="fade-up">{children}</Heading>,
    h2: ({ children }) => <Heading data-aos="fade-up">{children}</Heading>,
    h3: ({ children }) => (
      <Subheading data-aos="fade-up">{children}</Subheading>
    ),
    h4: ({ children }) => (
      <Subheading data-aos="fade-up">{children}</Subheading>
    ),
    h5: ({ children }) => (
      <Subheading data-aos="fade-up">{children}</Subheading>
    ),
    h6: ({ children }) => (
      <Subheading data-aos="fade-up">{children}</Subheading>
    ),
    normal: ({ children }) => <Body data-aos="fade-up">{children}</Body>,
  },
  listItem: {
    bullet: ({ children }) => <li data-aos="fade-left">{children}</li>,
    number: ({ children }) => <li data-aos="fade-left">{children}</li>,
  },
  types: {
    image: ({ value }) => (
      <Image
        data-aos="zoom-in"
        src={`${value?.asset?.url}?w=800${isGIF(value?.asset) ? '' : '&fm=webp'}`}
        alt={value?.alt}
        placeholderSrc={`${value?.asset?.url}?w=20${isGIF(value?.asset) ? '' : '&fm=webp'}`}
        wrapperClassName="single-image-wrapper"
        srcSet={`
            ${value?.asset?.url}?w=320${isGIF(value?.asset) ? '' : '&fm=webp'} 320w,
            ${value?.asset?.url}?w=480${isGIF(value?.asset) ? '' : '&fm=webp'} 480w,
            ${value?.asset?.url}?w=800${isGIF(value?.asset) ? '' : '&fm=webp'} 800w,
            ${value?.asset?.url}?w=900${isGIF(value?.asset) ? '' : '&fm=webp'} 900w
          `}
      />
    ),
    gallery: ({ value }) => (
      <Masonry columns={value?.columns} gutter={value?.gutter}>
        {value?.images.map((image) => (
          <Image
            data-aos="zoom-in"
            css={{ margin: 0 }}
            src={`${image?.asset?.url}?w=480&fm=webp`}
            alt={image?.alt}
            placeholderSrc={`${image?.asset?.url}?w=20&fm=webp`}
            srcSet={`
                ${image?.asset?.url}?w=320&fm=webp 320w,
                ${image?.asset?.url}?w=480&fm=webp 480w
              `}
            wrapperClassName="gallery-image-wrapper"
          />
        ))}
      </Masonry>
    ),
    button: ({ value }) =>
      value.calendlyButton ? (
        <AppContext.Consumer>
          {({ proposalData }) => (
            <CalendlyButton
              data-aos="zoom-in"
              url={
                value.calendlyUrl || proposalData?.accountManager?.calendlyUrl
              }
              variant={value.variant}
              marginBottom={value?.marginBottom}
              marginTop={value?.marginTop}
            >
              {value?.text}
            </CalendlyButton>
          )}
        </AppContext.Consumer>
      ) : (
        <Button
          data-aos="zoom-in"
          variant={value.variant}
          marginBottom={value?.marginBottom}
          marginTop={value?.marginTop}
        >
          {value?.text}
        </Button>
      ),
  },
} as Partial<PortableTextReactComponents>;

export default PortableTextComponents;
