import styled from '@emotion/styled';
import Masonry from 'react-responsive-masonry';

import { SharedAtomProps } from './typings';

const StyledMasonry = styled(Masonry)`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

interface MasonryProps extends SharedAtomProps {
  columns?: number;
  /**
   * Margin surrounding each item e.g. "10px" or "1.5rem"
   */
  gutter?: string;
}

const MasonryComponent = ({
  children,
  gutter = '10px',
  columns,
  ...otherProps
}: MasonryProps) => {
  return (
    <StyledMasonry columnsCount={columns} gutter={gutter} {...otherProps}>
      {children}
    </StyledMasonry>
  );
};

export default MasonryComponent;
