import styled from '@emotion/styled';

import { breakpoints, colors } from '../../../emotion/constants';
import { Section } from '../../atoms';

export const FooterSection = styled(Section)<{ themed?: boolean }>`
  background-color: ${({ theme, themed }) =>
    themed ? theme.colors.background : colors.black};
  color: ${({ theme, themed }) => (themed ? theme.colors.text : colors.white)};
  padding-top: 2.06rem !important;
  padding-bottom: 2.06rem !important;
  display: flex;
  gap: 1rem;
  justify-content: center;
  width: 100%;

  & > p {
    width: auto;
    margin: 0;
  }

  ${breakpoints.tabletLandscapeUp} {
    flex-direction: row;
  }
`;
