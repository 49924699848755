import styled from '@emotion/styled';

import { colors } from '../../emotion/constants';
import Monospace from './Monospace';

interface BulletProps {
  children: string;
  active?: boolean;
}

const BulletWrapper = styled.button<{ active?: boolean }>`
  padding: 0.58rem 1.03rem;
  border-radius: 5rem;
  border: 1px solid ${colors.black};
  background-color: ${({ active }) => (active ? colors.yellow : 'transparent')};

  &:hover {
    background-color: ${colors.yellow};
  }
`;

export default function Bullet({ active = false, children }: BulletProps) {
  return (
    <BulletWrapper active={active}>
      <Monospace>{children}</Monospace>
    </BulletWrapper>
  );
}
