import styled from '@emotion/styled';

import { fonts } from '../../emotion/constants';
import { SharedAtomProps } from './typings';

const StyledCaption = styled.p`
  font-family: ${fonts.text};
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
`;

export default function Caption(props: SharedAtomProps) {
  const { children, ...otherProps } = props;
  return <StyledCaption {...otherProps}>{children}</StyledCaption>;
}
