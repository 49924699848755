import styled from '@emotion/styled';

import { breakpoints, fonts } from '../../emotion/constants';
import { HeadingProps } from './typings';

const StyledHeading = styled.h2<HeadingProps>`
  font-family: ${fonts.title};
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.0375rem;
  margin-top: 0;

  ${({ noMargin }) => noMargin && 'margin-bottom: 0;'}
  ${({ centered }) => centered && 'text-align: center;'}

  ${breakpoints.desktopUp} {
    font-size: 4.1875rem;
    letter-spacing: 0.08375rem;
  }
`;

export default function Heading(props: HeadingProps) {
  const { children, as = 'h2', ...otherProps } = props;
  return (
    <StyledHeading as={as} {...otherProps}>
      {children}
    </StyledHeading>
  );
}
