import styled from '@emotion/styled';
import { ReactElement } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { SharedAtomProps } from './typings';

interface ImageProps extends Omit<SharedAtomProps, 'children'> {
  src: string;
  alt: string;
  centered?: boolean;
  fullWidth?: boolean;
  srcSet?: string;
  placeholder?: ReactElement;
  placeholderSrc?: string;
  wrapperClassName?: string;
}

const StyledImage = styled(LazyLoadImage)<ImageProps>`
  margin-bottom: 2rem;
  margin-top: 2rem;
  max-width: 900px;
  width: 100%;
  height: auto;
  display: block;

  ${({ fullWidth }) => fullWidth && `min-width: 100%;`};
  ${({ centered }) =>
    centered &&
    `
    margin-left: auto;
    margin-right: auto;
  `};
`;

export default function Image(props: ImageProps) {
  return <StyledImage {...props} effect="blur" />;
}
