import { PortableText } from '@portabletext/react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLoaderData } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ProposalHero, Section } from '../components/atoms';
import ConfirmationModal from '../components/molecules/ConfirmationModal';
import NavBar from '../components/molecules/NavBar';
import Footer from '../components/organisms/Footer';
import { useApp } from '../contexts/AppContext';
import PortableTextComponents from '../helpers/PortableTextComponents';
import { buildURL } from '../helpers/ProposalURL';
import { updateProposalStatus } from '../helpers/SanityMutations';
import {
  sendEmailToAccountManager,
  sendEmailToClient,
} from '../helpers/Sendgrid';
import useProposal from '../hooks/useProposal';
import ErrorView from './ErrorView';

export async function loader({ params }) {
  const { proposalId } = params;
  return { proposalId };
}

function SingleProposal() {
  const { proposalId } = useLoaderData() as { proposalId: string };
  const { data: proposalQueryData, loading, error } = useProposal(proposalId);

  const {
    setMutationsLoading,
    setModalVisible,
    setProposalData,
    proposalData,
  } = useApp();

  useEffect(() => {
    setProposalData(proposalQueryData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposalQueryData]);

  const handleApproval = async () => {
    setMutationsLoading(true);
    try {
      await sendEmailToClient(
        proposalData?.client?.email,
        proposalData?.client?.name,
        proposalData?.client?.contactName,
      );
      await sendEmailToAccountManager(
        proposalData?.accountManager?.email,
        proposalData?.accountManager?.name,
        buildURL(proposalData?._id),
        proposalData?.client?.name,
        proposalData?.client?.contactName,
        proposalData?.client?.email,
        proposalData?.client?.phone,
      );
      await updateProposalStatus(proposalId, true);
      setProposalData({
        ...proposalData,
        approved: true,
      });
      toast.success(
        'La cotización se marcó como aprobada y te enviamos un correo con los siguientes pasos.',
      );
    } catch {
      toast.error('Error');
    } finally {
      setMutationsLoading(false);
      setModalVisible(false);
    }
  };

  if (error) {
    return <ErrorView />;
  }

  return (
    <>
      <Helmet>
        <title>
          {proposalData?.client?.name} - {proposalData?.title}
        </title>
      </Helmet>
      <div className="SingleProposal">
        <NavBar />

        <ProposalHero
          backgroundImage={`${proposalData?.mainImage?.asset?.url}?w=1400&fm=webp`}
          color={
            proposalData?.mainImage?.asset?.metadata?.palette?.dominant
              ?.background
          }
          title={proposalData?.title}
          creationDate={proposalData?._createdAt}
          client={proposalData?.client?.name}
        />

        {loading ? (
          <Section loading={loading} />
        ) : (
          proposalData?.sections.map((section) => (
            <Section
              key={section._key}
              loading={loading}
              dark={section.dark}
              backgroundColor={section?.backgroundColor?.value}
              textColor={section?.textColor?.value}
            >
              <PortableText
                value={section.content}
                components={PortableTextComponents}
              />
            </Section>
          ))
        )}
        <Footer />

        <ConfirmationModal onApprove={handleApproval} />
        <ToastContainer />
      </div>
    </>
  );
}

export default SingleProposal;
