import styled from '@emotion/styled';
import { ReactNode } from 'react';

import { breakpoints, colors, fonts } from '../../emotion/constants';

interface BlockQuoteProps {
  children: ReactNode;
  color?: string;
}

const StyledBlockQuote = styled.blockquote<{ color?: string }>`
  color: ${colors.black};
  font-family: ${fonts.title};
  font-size: 2.6875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  letter-spacing: 0.05375rem;
  background-color: ${({ color }) => color};
  padding: 2.5rem 2rem 2.58rem;
  margin-left: -1.13rem;
  margin-right: -1.13rem;
  display: flex;
  flex-direction: column;
  gap: 2.64rem;

  & > * {
    max-width: 100%;
    margin: 0;
  }

  ${breakpoints.desktopUp} {
    margin-left: -1.97rem;
    margin-right: -1.97rem;
  }
`;

export default function BlockQuote(props: BlockQuoteProps) {
  const { children, color = colors.pink_light } = props;
  return <StyledBlockQuote color={color}>{children}</StyledBlockQuote>;
}
