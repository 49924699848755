import styled from '@emotion/styled';

import { fonts } from '../../emotion/constants';
import { SharedAtomProps } from './typings';

const StyledInternalPageH1 = styled.h1`
  font-family: ${fonts.title};
  font-size: 6.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: 0.1375rem;
`;

export default function InternalPageH1(props: SharedAtomProps) {
  const { children, ...otherProps } = props;
  return (
    <StyledInternalPageH1 {...otherProps}>{children}</StyledInternalPageH1>
  );
}
