export const colors = {
  black: '#1C1C1C',
  white: '#FFFFFF',
  gray_1: '#C4CACE',
  gray_2: '#E7EDEF',
  gray_3: '#F3F6F7',
  orange: '#FF4D16',
  orange_light: '#FAA085',
  yellow: '#E1FD6F',
  yellow_light: '#EAF8B1',
  pink: '#FFCCD2',
  pink_light: '#FAE0E3',
};

export const fonts = {
  text: `'Space Grotesk', sans-serif`,
  title: `'Radio Grotesk', sans-serif`,
  mono: `'Space Mono', monospace`,
};

export const screenWidths = {
  phoneDown: 600,
  tabletPortraitDown: 900,
  tabletLandscapeDown: 1200,
  desktopDown: 1800,

  phoneUp: 0,
  tabletPortraitUp: 601,
  tabletLandscapeUp: 901,
  desktopUp: 1201,
  largeDesktopUp: 1801,
};

export const breakpoints = {
  phoneDown: `@media (max-width: ${screenWidths.phoneDown}px)`,
  tabletPortraitDown: `@media (max-width: ${screenWidths.tabletPortraitDown}px)`,
  tabletLandscapeDown: `@media (max-width: ${screenWidths.tabletLandscapeDown}px)`,
  desktopDown: `@media (max-width: ${screenWidths.desktopDown}px)`,

  phoneUp: `@media (min-width: ${screenWidths.phoneUp}px)`,
  tabletPortraitUp: `@media (min-width: ${screenWidths.tabletPortraitUp}px)`,
  tabletLandscapeUp: `@media (min-width: ${screenWidths.tabletLandscapeUp}px)`,
  desktopUp: `@media (min-width: ${screenWidths.desktopUp}px)`,
  largeDesktopUp: `@media (min-width: ${screenWidths.largeDesktopUp}px)`,
};
